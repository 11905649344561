import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import logoicon from "../../assets/images/logo-icon.png";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";

import { Tooltip, Drawer, Menu, MenuItem } from "@mui/material";
export default function SidebarPage() {
  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div className="sidevar-head">
          <img src={logo} alt="" className="logo-main" />
          <img src={logoicon} className="logoicon" />
        </div>
        <div className="sidebar-menu">
          <ul>
            {/* <li className>
               <Tooltip className="blue-tooltip"  title="Dashboard" placement="left" arrow>
                  <Link to="/dashboard" className={`${stepform === 'dashboard' ? "active" : ''}`} onClick={() => {setStepForm('dashboard')}}>
                  <DashboardCustomizeOutlinedIcon/>
                  <span>Dashboard</span>
                  </Link>
               </Tooltip>
            </li> */}

            <li className>
              <Tooltip title="Trust Request List" placement="left" arrow>
                <Link
                  to="/trust-request"
                  
                >
                  <CategoryOutlinedIcon />
                  <span>Trust Request</span>
                </Link>
              </Tooltip>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
