import React,  {useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {  } from '@mui/material'; 
 
import MainPage from '../_layout/main.page'; 
import { useSelector } from 'react-redux';
 
export const Dashboard = () => { 
 
  const navigate = useNavigate();
  const login = localStorage.getItem('login') === 'true'

  useEffect(() => {
    if(!login)
    {
      navigate('login');
    }
  },[login])
return (
<MainPage>
<div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className=" ">Dashboard</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active" />
            </ol>
          </div>
        </div>
   <div className="page-body">
    
         <div className='container'>
            <div className='row'>
        
            </div>
         </div>
    
   </div>
</MainPage>
)
}