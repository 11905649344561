import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainPage from "../_layout/main.page";
import host from "../host";
import MessageSnackbar from "../_layout/elements/snackbar";
import { CircularProgress } from "@mui/material";
import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import StellarBase from "stellar-base";
const {
  Keypair,
  Horizon,
  TransactionBuilder,
  Networks,
  Operation,
  Asset,
  BASE_FEE,
} = require("@stellar/stellar-sdk");

export const TrustDetails = () => {

 
  const id = useParams();
  const server = new Horizon.Server(host.horizon);
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const onClose = () => {
    setSnackbar(false);
  };

  const [statusData, setStatusData] = useState({});
  const [walletAddress, setWalletAddress] = useState("");
  const [assetCode, setAssetCode] = useState("");
  const [assetIssuer, setAssetIssuer] = useState("");
  const [loading, setLoading] = useState(true);
  const assetsList = JSON.parse(host.assets);
  const [isTrust, seIsTrust] = useState(false);
  const [isAuthorized, seIsAuthorized] = useState(false);
  const [secretKey, setSecretKey] = useState("");
  const [loading2, setLoading2] = useState(false);
  const [balance, setBalance] = useState(0);

  const checkStatus = (id) => {
    let mainquery = `/api/v1/trustCheck/${id}`;

    fetch(host.hostname + mainquery, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          setStatusData(data.category);
          setWalletAddress(data.category.wallet);
          if (data.category.assets) {
            setAssetIssuer(assetsList[data.category.assets]);
          }
          setAssetCode(data.category.assets);
        } else {
          setSeverity("error");
          setMessage(data.message);
          setSnackbar(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (id && id.id) {
      checkStatus(id.id);
    }
  }, [id]);

  const updateStatus = (hash) => {
    fetch(host.hostname + "/api/v1/trustRequest/" + id.id, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        status: !isAuthorized ? "APPROVED" : "REVOKED",
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          setSeverity("success");
          setMessage("Transaction successfull hash " + hash);
          setSnackbar(true);
          setSecretKey("");
          setWalletAddress("");
          checkStatus(id.id);
        } else {
          setMessage(data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchDetails = async () => {
    try {
      const data = await server.loadAccount(walletAddress);
      console.log(assetIssuer);
      if (data?.balances) {
        if (data?.balances?.length > 0) {
          data.balances.map(function (object, i) {
            if (
              object.asset_code == assetCode &&
              object.asset_issuer == assetIssuer
            ) {
              seIsTrust(true);
              seIsAuthorized(object.is_authorized_to_maintain_liabilities);
              setBalance(object.balance)
            }
          });
        }
      }
    } catch (e) {
      // setSeverity("error");
      // setMessage("An error has occured.");
      // setSnackbar(true);
    }
    setLoading(false);
  };

  const trustAction = async () => {
    setLoading2(true);
    const account = await server.loadAccount(assetIssuer);
    const abcAsset = new Asset(assetCode, assetIssuer);

    try {
      var issuingKeys = Keypair.fromSecret(secretKey);

      const transaction = new TransactionBuilder(account, {
        fee: BASE_FEE,
        networkPassphrase: host.passphrase,
      })
        .addOperation(
          Operation.setTrustLineFlags({
            asset: abcAsset,
            source: assetIssuer,
            flags: {
              authorized: !isAuthorized,
            },
            trustor: walletAddress,
          })
        )
        .setTimeout(30)
        .build();

      transaction.sign(issuingKeys);

      try {
        const transactionResult = await server.submitTransaction(transaction);
        updateStatus(transactionResult.hash);
      } catch (e) {
        console.log(e);
        setSeverity("error");
        setMessage(e.message);
        setSnackbar(true);
      }
    } catch (e) {
      setSeverity("error");
      setMessage("Invalid secret key.");
      setSnackbar(true);
    }
    setLoading2(false);
  };

  // fetch wallet details
  useEffect(() => {
    if (walletAddress != "") {
      fetchDetails();
    }
  }, [walletAddress]);

  function formatDateToDMY(dateString) {
    const dateObject = new Date(dateString);
    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: "long" });
    const year = dateObject.getFullYear();
    return `${day} ${month} ${year}`;
  }

  return (
    <MainPage>
      <MessageSnackbar
        open={snackbar}
        autoHideDuration={3000}
        onClose={onClose}
        severity={severity}
        message={message}
      />
      <div>
        <div className="course-detail-banner">
          <div className="container">
            {loading ? (
              <>
                <div style={{ marginLeft: 30, marginTop: 30 }}>
                  <CircularProgress />
                </div>
              </>
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="welcometext"
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    <h5 className="mb-0">Request Status</h5>
                  </div>

                  {statusData && statusData._id && (
                    <div>
                      <div className="row">
                        <div className="col-xxl-12 col-lg-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              {" "}
                              Request ID : {statusData.uuid}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xxl-12 col-lg-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              {" "}
                              Name : {statusData.name}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xxl-12 col-lg-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              {" "}
                              Wallet address: {statusData.wallet}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xxl-12 col-lg-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              {" "}
                              Email : {statusData.email}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xxl-12 col-lg-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Mobile : {statusData.mobile}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xxl-12 col-lg-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Assets : {statusData.assets} 
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-12 col-lg-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              {assetCode} Balance :  {balance}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xxl-12 col-lg-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Country : {statusData.country}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xxl-12 col-lg-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Date : {formatDateToDMY(statusData.createdAt)}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xxl-12 col-lg-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Status : {statusData.status}
                            </label>
                          </div>
                        </div>
                      </div>
                      {isTrust ? (
                        <>
                          <div className="row">
                            <div className="col-xxl-6 col-lg-6 col-md-6">
                              <div className="form-group">
                                <label className="form-label">
                                  {" "}
                                  Issuer Secret Key
                                </label>
                                <TextField
                                  size="small"
                                  type="text"
                                  name="name"
                                  placeholder="Enter Issuer Secret Key	"
                                  value={secretKey}
                                  onChange={(e) => {
                                    setSecretKey(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-xxl-6 col-lg-6 col-md-6">
                            <div className="form-group">
                              <LoadingButton
                                disabled={!secretKey || loading2}
                                type="button"
                                variant="contained"
                                className="btn btn-primary w-100  "
                                controlled={true}
                                loading={loading2}
                                onClick={trustAction}
                              >
                                {isAuthorized
                                  ? "Revoke Trust"
                                  : "Authorize Trust"}
                              </LoadingButton>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="row">
                          <div className="col-xxl-12 col-lg-12 col-md-12">
                            <div className="form-group">
                              <label
                                className="form-label"
                                style={{ color: "red" }}
                              >
                                Trust not applied by user
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </MainPage>
  );
};
