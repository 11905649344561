import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Tooltip,
  Zoom,
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
  Modal,
  Box,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  CircularProgress,
  Pagination,
} from "@mui/material";
import MainPage from "../_layout/main.page";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import host from "../host";
import { useDispatch, useSelector } from "react-redux";

import MessageSnackbar from "../_layout/elements/snackbar";
import Stack from "@mui/material/Stack";



export const RequestList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [videos, setVideos] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const router = useNavigate();

  const { auth } = useSelector((state) => {
    return state;
  });

  const onClose = () => {
    setSnackbar(false);
  };

  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  const getListing = () => {
    setIsLoaded(false);

    let mainQuery = `/api/v1/trustRequestList?page=${page}&limit=10`;

    if (searchText) {
      mainQuery += `&search_string=${searchText}`;
    }

    fetch(host.hostname + mainQuery, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        if (res.status === 401) {
          //dispatch(changeAuth(false, null, null));
          navigate("/admin");
          return;
        } else {
          return res.json();
        }
      })
      .then((data) => {
        if (data.success) {
          setVideos(data.data);
          setTotalCount(data.total_count);
        } else {
          setSeverity("error");
          setMessage(data.message);
          setSnackbar(true);
        }
        setTimeout(() => {
          setIsLoaded(true);
        }, [500]);
      })
      .catch((err) => {
        setIsLoaded(true);
        console.log(err);
      });
  };

  



  

  useEffect(() => {
      getListing();
  }, [page,searchText]);

  function formatDateToDMY(dateString) {
    const dateObject = new Date(dateString);
    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: "long" });
    const year = dateObject.getFullYear();
    return `${day} ${month} ${year}`;
  }

  //this below function for delete member   popup start//
  const [opendelete, setOpendelete] = React.useState(false);
  const activedelet = () => setOpendelete(true);
  const handleClosedelete = () => setOpendelete(false);
  //this below function for delete member   popup end//
  //this below function for open action box table  start//
  const [isVisible, setIsVisible] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsVisible(event.target.checked);
  };
  //this below function for open action box table  end//
  return (
    <MainPage>
      <MessageSnackbar
        open={snackbar}
        autoHideDuration={3000}
        onClose={onClose}
        severity={severity}
        message={message}
      />
      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 className=" "> All Trust Request</h4>
        <div className="page-title-right">
          {/* <button type='button' className='btn btn-secondry disabled' > SAVE</button> */}
        </div>
      </div>
      <div className="page-body">
        <div className="container">
          <div className="row  ">
            <div className="col-md-12">
              <div className="search-collaborator">
                <div className="search-candidate ">
                  <div className="search-candidate-icon">
                    <SearchOutlinedIcon />
                  </div>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      type="text"
                      size="small"
                      placeholder="Search request by wallet address"
                      className="searchbarcandidate"
                      value={searchText}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                        
                      }}
                    />
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="web-table ">
                {isVisible && (
                  <div className="candidate-table-action">
                    <div className="card">
                      <div className="card-body">
                        <div className="candidate-table-action-row">
                          <div className="invitesendbox">
                            <button
                              type="button"
                              className="btn btn-secondry"
                              onClick={activedelet}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isLoaded && videos && videos.length > 0 ? (
                  <TableContainer className=" ">
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                           <TableCell>ID </TableCell>
                          <TableCell>Name </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            Email
                          </TableCell>
                          <TableCell> Wallet </TableCell>
                          <TableCell>Mobile </TableCell>
                          <TableCell>Country </TableCell>
                          <TableCell> Assets </TableCell>
                          <TableCell> Status </TableCell>
                          <TableCell> Date </TableCell>
                          <TableCell> Action </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {isLoaded &&
                          videos &&
                          videos.length > 0 &&
                          videos.map((video, index) => {
                            let pageno = (page - 1) * 10 + 1;
                            if (page === 1) {
                              pageno = 1;
                            }
                            return (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                
                               <TableCell>
                           <span className="access-role">
                           {video.uuid}
                           </span>
                           </TableCell> 
                              
                           <TableCell>
                           <span className="access-role">
                           {video.name}
                           </span>
                           </TableCell>

                            <TableCell>
                           <span className="access-role">
                           {video.email}
                           </span>
                           </TableCell>

                            <TableCell>
                           <span className="access-role">
                           {video.wallet}
                           </span>
                           </TableCell>

                            <TableCell>
                           <span className="access-role">
                           {video.mobile}
                           </span>
                           </TableCell>

                           <TableCell>
                           <span className="access-role">
                           {video.country}
                           </span>
                           </TableCell>

                            <TableCell>
                           <span className="access-role">
                           {video.assets}
                           </span>
                           </TableCell>

                            <TableCell>
                                  {video.status}
                                </TableCell>

                                <TableCell>
                                  {" "}
                                  {formatDateToDMY(video.createdAt)}
                                  
                                </TableCell>
                                <TableCell>
                                  <div className="resume-action justify-content-center">
                                    <Tooltip
                                      className="blue-tooltip"
                                      title="View Detail "
                                      TransitionComponent={Zoom}
                                      placement="top"
                                      arrow
                                    >
                                      <Link
                                        to={`/trust-details/${video.uuid}`}
                                      >
                                        <i class="las la-eye"></i>{" "}
                                      </Link>
                                    </Tooltip>
                                  
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Stack m={2} spacing={2}>{isLoaded ? <p>No record found</p> : <p>Wait...</p>}</Stack>
                )}

               

                <div className="tfoot-area">
                  {totalCount > 10 && isLoaded && (
                    <Stack m={2} spacing={2}>
                      <Pagination
                        page={page}
                        onChange={(_, page) => {
                          // Always need safety check for page !== null
                          if (page !== null) {
                            setPage(page);
                            const urlParams = new URLSearchParams(
                              window.location.search
                            );
                            

                            navigate({
                              pathname: "/trust-request",
                              search: urlParams.toString(),
                            });
                          }
                        }}
                        count={Math.ceil(totalCount / 10)}
                        color="primary"
                      />
                    </Stack>
                  )}
                </div>

              
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={opendelete}
        onClose={handleClosedelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className=" commonpopup  modal-common   popup-w35 ">
          <Link className="close" onClick={handleClosedelete}>
            X
          </Link>
          <div className="modal-body">
            <div className="icon-box">
              <i class="las la-trash-alt"></i>
            </div>
            <div className="model-content text-center">
              <h3>Delete forever </h3>
              <FormControlLabel
                control={<Checkbox />}
                label="I understand that deleting is permanent and can't be undone
               "
              />
            </div>
            <div className="modal-btn">
              <button
                className="btn btn-success btn-block  "
                onClick={handleClosedelete}
              >
                Delete forever
              </button>
              <button
                className="btn btn-danger btn-block  "
                onClick={handleClosedelete}
              >
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </MainPage>
  );
};
