import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {Tooltip,Zoom,Modal,Box,FormControl,TextField ,CircularProgress} from '@mui/material';
import MainPage from '../_layout/main.page';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ssc from '../assets/images/SSC_All_1594144453.webp';
import bank from '../assets/images/bank.png';
import teaching from '../assets/images/teaching.png';
import Civil from '../assets/images/civil.webp';
import railway from '../assets/images/railway.webp';
import Defence from '../assets/images/defence.jpeg';
import category from '../assets/images/category.png';
import MessageSnackbar from '../_layout/elements/snackbar';
import { useSelector } from 'react-redux';
import host from '../host';
import PageLoader from '../components/page-loader';

export const CourseCategory = () => {
//this below function for open save popup start// 
const [open, setOpen] = React.useState(false);
const active = () => setOpen(true);
const handleClose = () => setOpen(false);
//this below function for open save popup end// 
//this below function for open save popup start// 
const [opendelet, setOpendelet] = React.useState(false);
const activedelet = () => setOpendelet(true);
const handleClosedelet = () => {setOpendelet(false); setEditId('');}
//this below function for open save popup end// 

// add category functionality begins

   const [openupdate, setOpenUpdate] = React.useState(false);
   const update = () => setOpenUpdate(true);
   const handleCloseUpdate = () => {setOpenUpdate(false);setEditId('');}

   const [snackbar, setSnackbar] = useState(false);
   const [message, setMessage] = useState('');
   const [severity, setSeverity] = useState('success');
   const onClose = () => {
      setSnackbar(false)
   }

   const navigate = useNavigate();
   const login = localStorage.getItem('login') === 'true'

   const [iconLoaded, setIconLoaded] = useState(true);
   const [iconSelected, setIconSelected] = useState('');
   const [categoryName, setCategoryName] = useState('');
   const [editId, setEditId] = useState('');
   const uploadIcon = (file) => {     

      let formData = new FormData()
      formData.append("image", file); 
      setIconLoaded(false); 

      fetch(host.hostname + '/api/v1/category/upload_image',{
         method: "POST",
         headers: {            
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
         },
         body: formData
       })
         .then((res) => {
            if (res.status == 401) {
               navigate('login')
               return;
            } else {
               return res.json();
            }
         })
         .then((data) => {
           if (data.success) {
             setIconLoaded(true); 
             if (file) {
               const reader = new FileReader();
         
               reader.onloadend = () => {
                 // Set the selected image and update the state
                 setIconSelected(reader.result);
               };
         
               reader.readAsDataURL(file);
             }
             setSeverity('success');
             setMessage('Icon uploaded successfully!');
             setSnackbar(true);
           } else {
            setIconLoaded(true);
           }
           
         })
         .catch((err) => {
           console.log(err);
           setIconLoaded(true);
         });
   }

   const getSingleCategory = (id) => {
      let mainquery = `/api/v1/category/${id}`;

      fetch(host.hostname + mainquery,{
         method: "GET",
         headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
         }
       })
         .then((res) => {
            if (res.status == 401) {
               navigate('/login')
               return;
            } else {
               return res.json();
            }
         })
         .then((data) => {
            
           if (data.success) {
               setCategoryName(data.category.name);
               setEditId(id);
               update();  //opens update modal
           } else {
            setSeverity('error');
             setMessage(data.message);
             setSnackbar(true);
           }           
         })
         .catch((err) => {
           console.log(err);
         });
   }

   const addCategory = (flag) => {

      let mainquery = '/api/v1/category';
      let method = ''

      if(flag === 'add')
      {
         method = 'POST'
      }
      else if(flag === 'update')
      {
         method = 'PUT';
         mainquery = mainquery + '/' +  editId;
      }

      fetch(host.hostname + mainquery,{
         method: method,
         headers: { 
           "Content-type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
         },
         body: JSON.stringify({
            name: categoryName,
            icon_url: null
          }),
      })
         .then((res) => {
            if (res.status == 401) {
               navigate('login')
               return;
            } else {
               return res.json();
            }
         })
         .then((data) => {

           if (data.success) {
             setIsLoaded(true);
             setSeverity('success');
             setMessage('Category uploaded successfully!');
             setSnackbar(true);
             handleReset();
           } else {
            setIconLoaded(true);
            setSeverity('error');
             setMessage(data.message);
             setSnackbar(true);
           }
         })
         .catch((err) => {
           console.log(err);
           setIconLoaded(true);
         });

   }

   const handleUpdate = (id) => {
      getSingleCategory(id);
   }

   const handleReset = () => {
      getCategories();
      handleClose();
      handleCloseUpdate();
      handleClosedelet();
      setCategoryName('');
      setIconSelected('');
   }

// add category functionality ends

// delete functionality begins

   const deleteCategory = () => {
      let mainquery = `/api/v1/category/${editId}`;
      
      fetch(host.hostname + mainquery,{
         method: "DELETE",
         headers: { 
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
         },})
         .then((res) => {
            if (res.status == 401) {
               navigate('login')
               return;
            } else {
               return res.json();
            }
         })
         .then((data) => {
           if (data.success) {
             setSeverity('success');
             setMessage('Category Deleted successfully!');
             setSnackbar(true);
             getCategories();
             handleReset();
           } else {          
            setSeverity('error');
             setMessage(data.message);
             setSnackbar(true);
           }
         })
         .catch((err) => {
           console.log(err);           
         });
   }

   const handleDelete = (id) => {
      setEditId(id);
      activedelet();
   }
//delete functionality ends

//category listing functionality begins

   const [isLoaded, setIsLoaded] = useState(true);
   const [categories, setCategories] = useState([]);

   const getCategories = () => {


      let mainquery = '/api/v1/category';

      fetch(host.hostname + mainquery,{
         method: "GET",
         headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
         }
       })
         .then((res) => {
            if (res.status == 401) {
               navigate('/login')
               return;
            } else {
               return res.json();
            }
         })
         .then((data) => {
           if (data.success) {
              setIsLoaded(true); 
              setCategories(data.categories);
           } else {
            setIsLoaded(true);
           }
           
         })
         .catch((err) => {
           console.log(err);
           setIsLoaded(true);
         });
   }

   useEffect(() => {
      if(login)
      {
         getCategories();
      }
      else{
         navigate('/login');
      }
   },[])
//category listing functionality ends


return (
<MainPage>
   <div>
   <MessageSnackbar
            open={snackbar}
            autoHideDuration={3000}
            onClose={onClose}
            severity={severity}
            message={message}
         />
      <div className="page-title-box  ">
         <h4 className=" ">Course Categories</h4>
         <div className="page-title-right">
            <Link to="#"  className="btn btn-primary  " onClick={active}>
            <i className="las la-plus" /> Add New category</Link>
         </div>
      </div>
      <div className="page-body">
         <div className="container">       
            <div className="row rowmargin">
              {(categories &&
               isLoaded &&
               categories.length > 0) ?
                categories.map((category,index) => {
                  return(<div className="col-lg-3 col-12 colmrg " key={index}>
                  <div className='category-box'>
                     <div className='category-card-head'>
                        <div className='category-icon'>
                           <img src={category.icon_url ? category.icon_url : ssc} alt='' />
                        </div>
                        <div className='edit_delet_actions'>
                           <Tooltip className="blue-tooltip"  title="Edit Category "  TransitionComponent={Zoom} placement="top" arrow>
                              <Link to="#" onClick={() => {handleUpdate(category._id)}}>
                              <i class="las la-pen"></i> </Link> 
                           </Tooltip>
                           <Tooltip className="blue-tooltip"  title="Delete Category"  TransitionComponent={Zoom} placement="top" arrow>
                              <Link to="#" onClick={() => {handleDelete(category._id)}}>
                              <i class="las la-trash-alt"></i> </Link> 
                           </Tooltip>
                        </div>
                     </div>
                     <div className='card-body'>
                        <div className='category-row'>
                           <div className='category-title'>
                              <h6>{category.name ? category.name : "NA"}</h6>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>)
               }) : isLoaded ? <div className="not-fount-table videoloader" style={{display:'block'}}  >
                                                <div className="not-fount-center" >
                                                   <div class="fond-icon-content text-center">
                                                      <div className="Nofound-image bigimage">
                                                         <img src={category}    alt=""  style={{width:'75px'}}/>
                                                      </div>
                                                      <div className="no-data-info__body">
                                                         <h5 className='d-flex align-items-center gap-3 justify-content-center'>No Category Found   </h5>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div> : <PageLoader />}
               {/* {categories.length === 0 && 
                  } */}
               
            </div>
         </div>
      </div>
   </div>
   <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      >
      <Box className=" commonpopup  modal-common  popup-w35  "  >
         <div className="modal-head">
            <Link to="#" className='close' onClick={handleClose}>
            X</Link>
         </div>
         <div className="modal-body">
            <div className='modal-title'>
               <h4>Add Category </h4>
            </div>
            <div className='modal-contant'>
               <div className='modal-form'>
                  <div className='modal-form-body'>
                     <div className='row'>
                        <div className='col-md-12'>
                           <div className='add-category-row'>
                              <div className='category-icon-left'>
                                 <div className="category-icon">
                                    <img src={iconSelected} alt="" />
                                    {/* 
                                    <div className="profile-loader">
                                       <CircularProgress />
                                    </div>
                                    */}                                     
                                 </div>
                                 <div className='cat-icon-action'>
                                 {/* {add icon ui here} */}
                                  <div className="cat-icon-add">   
                                 <input  type="file" id="imageUploadnew"  className="ec-image-upload" accept="image/*"  onChange={(e) => {uploadIcon(e.target.files[0]);e.target.value=''}} />                                    
                                 <label htmlFor="imageUploadnew" className='btn btn-default'>  <i className="las la-plus" />  Add Icon</label>
                                    </div>
                                    <Link to="" className='btn btn-danger'>
                                    Remove Icon</Link>
                                 </div>
                              </div>
                              <div className='category-icon-right'>
                                 <div className='form-group mb-0'>
                                    <label>Category Name <span className='text-danger'>*</span></label>
                                    <FormControl fullWidth>
                                       <TextField
                                          size='small'
                                          placeholder='Enter Category Name'
                                          value={categoryName}
                                          onChange={(e) => {setCategoryName(e.target.value);}}
                                          />
                                    </FormControl>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='see-button  '>
                     <button type='button' className='btn btn-primary' onClick={() => {addCategory('add')}} > Save </button>
                     
                     <button type='button' className='btn btn-default' onClick={handleClose} > Cancel</button>
                  </div>
               </div>
            </div>
         </div>
      </Box>
   </Modal>
   <Modal
      open={openupdate}
      onClose={handleCloseUpdate}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      >
      <Box className=" commonpopup  modal-common  popup-w35  "  >
         <div className="modal-head">
            <Link to="#" className='close' onClick={handleCloseUpdate}>
            X</Link>
         </div>
         <div className="modal-body">
            <div className='modal-title'>
               <h4>Update Category </h4>
            </div>
            <div className='modal-contant'>
               <div className='modal-form'>
                  <div className='modal-form-body'>
                     <div className='row'>
                        <div className='col-md-12'>
                           <div className='add-category-row'>
                              <div className='category-icon-left'>
                                 <div className="category-icon">
                                 <img src={iconSelected} alt="" />
                                    {/* 
                                    <div className="profile-loader">
                                       <CircularProgress />
                                    </div>
                                    */}                                     
                                 </div>
                                 <div className='cat-icon-action'>
                                 {/* {add icon ui here} */}
                                  <div className="cat-icon-add">   
                                 <input  type="file" id="imageUploadnew"  className="ec-image-upload" accept="image/*"  onChange={(e) => {uploadIcon(e.target.files[0]);e.target.value=''}} />                                    
                                 <label htmlFor="imageUploadnew" className='btn btn-default'>  <i className="las la-plus" />  Add Icon</label>
                                    </div>
                                    {/* <Link to="" className='btn btn-danger'>
                                    Remove Icon</Link> */}
                                 </div> 
                              </div>
                              <div className='category-icon-right'>
                                 <div className='form-group mb-0'>
                                    <label>Category Name <span className='text-danger'>*</span></label>
                                    <FormControl fullWidth>
                                       <TextField
                                          size='small'
                                          placeholder='Enter Category Name'
                                          value={categoryName}
                                          onChange={(e) => {setCategoryName(e.target.value);}}
                                          />
                                    </FormControl>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='see-button  '>
                     <button type='button' className='btn btn-primary' onClick={() => {addCategory('update')}} > Save </button>
                     
                     <button type='button' className='btn btn-default' onClick={handleCloseUpdate} > Cancel</button>
                  </div>
               </div>
            </div>
         </div>
      </Box>
   </Modal>
   <Modal
      open={opendelet}
      onClose={handleClosedelet}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      >
      <Box className=" commonpopup  modal-common  popup-w35  "  >
         <Link to="#" className="close" onClick={handleClosedelet}>
         X</Link>
         <div className="modal-body">
            <div className="icon-box">
               <i class="las la-trash-alt"></i>
            </div>
            <div className="model-content text-center">
               <h3>Are you sure you want to Delete ? </h3>
            </div>
            <div className="modal-btn">
               <button className="btn btn-success btn-block  " onClick={deleteCategory}>Confirm</button>
               <button className="btn btn-danger btn-block  " onClick={handleClosedelet}>Cancel</button>
            </div>
         </div>
      </Box>
   </Modal>
</MainPage>
)
}