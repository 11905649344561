import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  TextField,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";

import logo from "../assets/images/logo-blue.png";

import { useFormik } from "formik";
import * as Yup from "yup";

import LoadingButton from "@mui/lab/LoadingButton";

import host from "../host";
import MessageSnackbar from "../_layout/elements/snackbar";

import countryList from "react-select-country-list";

export const TrustRequest = () => {
  const assetsList = Object.keys(JSON.parse(host.assets));
  console.log(assetsList);
  const options = useMemo(() => countryList().getData(), []);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [statusData, setStatusData] = useState({});

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    name: Yup.string().required("Password is required"),
    mobile: Yup.string().required("Mobile is required"),
    wallet: Yup.string().required("Wallet address is required"),
    assets: Yup.string().required("Assets is required"),
    country: Yup.string().required("Country is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      mobile: "",
      wallet: "",
      assets: "SHD",
      country: "",
    },
    validationSchema: validationSchema,
  });

  const [message, setMessage] = useState("");
  const [successColor, setSuccessColor] = useState(false);
  const [id, setId] = useState("");

  const [snackbar, setSnackbar] = useState(false);
  const [message2, setMessage2] = useState("");
  const [severity, setSeverity] = useState("success");
  const onClose = () => {
    setSnackbar(false);
  };

  const loginUser = () => {
    setMessage("");
    setLoading(true);

    fetch(host.hostname + "/api/v1/trustRequest", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email: formik.values.email,
        name: formik.values.name,
        mobile: formik.values.mobile,
        wallet: formik.values.wallet,
        assets: formik.values.assets,
        country: formik.values.country,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          setLoading(false);
          setSeverity("success");
          setMessage2(
            "Request submitted successfully request id is " + data.data.uuid
          );
          setSnackbar(true);
          formik.resetForm();
        } else {
          setMessage(data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const checkStatus = () => {
    if (id === "") {
      setSeverity("error");
      setMessage2("Please enter valid request Id");
      setSnackbar(true);
      return;
    }

    let mainquery = `/api/v1/trustCheck/${id}`;

    fetch(host.hostname + mainquery, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          setStatusData(data.category);
        } else {
          setSeverity("error");
          setMessage2(data.message);
          setSnackbar(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  

  return (
    <div>
      <MessageSnackbar
        open={snackbar}
        autoHideDuration={3000}
        onClose={onClose}
        severity={severity}
        message={message2}
      />
      <div className="auth-page">
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div className="col-xxl-6 col-lg-6 col-md-6">
              <div className="auth-full-page-content d-flex p-sm-4 p-4">
                <div className="form-width login-width ">
                  <div className="d-flex flex-column ">
                    <div className="auth-content my-auto logincorporate">
                      <div className="mb-4 mb-md-4 text-center">
                        <div className="flip-logo">
                          <img className=" " src={logo} height={50} />
                        </div>
                      </div>
                      <div className="welcometext text-center">
                        <h5 className="mb-0">Wallet Trust Request </h5>
                      </div>

                      <form className=" mt-2" action="index.html">
                        <div className="row">
                          <div className="col-xxl-12 col-lg-12 col-md-12">
                            <div className="form-group">
                              <label className="form-label"> Name</label>
                              <TextField
                                size="small"
                                type="text"
                                name="name"
                                placeholder="Enter name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.name &&
                                  Boolean(formik.errors.name)
                                }
                                helperText={
                                  formik.touched.name && formik.errors.name
                                }
                              />
                            </div>
                          </div>

                          <div className="col-xxl-12 col-lg-12 col-md-12">
                            <div className="form-group">
                              <label className="form-label"> Email</label>
                              <TextField
                                size="small"
                                type="text"
                                name="email"
                                placeholder="Enter email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.email &&
                                  Boolean(formik.errors.email)
                                }
                                helperText={
                                  formik.touched.email && formik.errors.email
                                }
                              />
                            </div>
                          </div>

                          <div className="col-xxl-12 col-lg-12 col-md-12">
                            <div className="form-group">
                              <label className="form-label"> Mobile</label>
                              <TextField
                                size="small"
                                type="text"
                                name="mobile"
                                placeholder="Enter mobile"
                                value={formik.values.mobile}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.mobile &&
                                  Boolean(formik.errors.mobile)
                                }
                                helperText={
                                  formik.touched.mobile && formik.errors.mobile
                                }
                              />
                            </div>
                          </div>

                          <div className="col-xxl-12 col-lg-12 col-md-12">
                            <div className="form-group">
                              <label className="form-label">
                                {" "}
                                Wallet Address
                              </label>
                              <TextField
                                size="small"
                                type="text"
                                name="wallet"
                                placeholder="Enter Wallet Address"
                                value={formik.values.wallet}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.wallet &&
                                  Boolean(formik.errors.wallet)
                                }
                                helperText={
                                  formik.touched.wallet && formik.errors.wallet
                                }
                              />
                            </div>
                          </div>

                          <div className="col-xxl-12 col-lg-12 col-md-12">
                            <div className="form-group">
                              <label className="form-label"> Country</label>

                              <Select
                                style={{ minWidth: 425 }}
                                name="country"
                                size="small"
                                value={formik.values.country}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.country &&
                                  Boolean(formik.errors.country)
                                }
                                helperText={
                                  formik.touched.country &&
                                  formik.errors.country
                                }
                                label="Country"
                              >
                                {options &&
                                  options.length > 0 &&
                                  options.map((option, index) => {
                                    return (
                                      <MenuItem value={option.label}>
                                        {option.label}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>
                          <div className="col-xxl-12 col-lg-12 col-md-12">
                            <div className="form-group">
                              <label className="form-label"> Assets Name</label>

                              <Select
                                style={{ minWidth: 425 }}
                                name="assets"
                                size="small"
                                value={formik.values.assets}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.assets &&
                                  Boolean(formik.errors.assets)
                                }
                                helperText={
                                  formik.touched.assets && formik.errors.assets
                                }
                                label="Assets"
                              >
                                {assetsList &&
                                  assetsList.length > 0 &&
                                  assetsList.map((asset, key) => {
                                    return (
                                      <MenuItem value={asset}>{asset}</MenuItem>
                                    );
                                  })}
                               
                              </Select>
                            </div>
                          </div>
                        </div>
                      </form>

                      <div className="col-xxl-12 col-lg-12 col-md-12">
                        <div className="form-group">
                          <LoadingButton
                            disabled={!formik.isValid}
                            type="button"
                            variant="contained"
                            className="btn btn-primary w-100  "
                            onClick={(e) => {
                              e.preventDefault();

                              loginUser();
                            }}
                            controlled={true}
                            loading={loading}
                          >
                            Submit
                          </LoadingButton>
                        </div>
                      </div>
                      <div className="  text-center notmember">
                        <div
                          style={{
                            color: successColor ? "green" : "red",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: 10,
                          }}
                        >
                          {message}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-lg-6 col-md-6">
              <div className="auth-full-page-content d-flex p-sm-4 p-4">
                <div className="form-width login-width ">
                  <div className="d-flex flex-column ">
                    <div className="auth-content my-auto logincorporate">
                      <div className="mb-4 mb-md-4 text-center">
                        <div className="flip-logo">
                          <img className=" " src={logo} height={50} />
                        </div>
                      </div>

                      <div className="welcometext text-center">
                        <h5 className="mb-0">Request Status</h5>
                      </div>

                      <div className="row">
                        <div className="col-xxl-12 col-lg-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label"> Request ID</label>
                            <TextField
                              size="small"
                              type="text"
                              name="name"
                              placeholder="Enter Request ID"
                              value={id}
                              onChange={(e) => {
                                setId(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-12 col-lg-12 col-md-12">
                        <div className="form-group">
                          <LoadingButton
                            type="button"
                            variant="contained"
                            className="btn btn-primary w-100  "
                            onClick={(e) => {
                              e.preventDefault();

                              checkStatus();
                            }}
                          >
                            Submit
                          </LoadingButton>
                        </div>
                      </div>
                      {statusData && statusData._id && (
                        <div>
                          <div className="row">
                            <div className="col-xxl-12 col-lg-12 col-md-12">
                              <div className="form-group">
                                <label className="form-label">
                                  {" "}
                                  Request ID : {statusData.uuid}
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-xxl-12 col-lg-12 col-md-12">
                              <div className="form-group">
                                <label className="form-label">
                                  {" "}
                                  Wallet Address : {statusData.wallet}
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-xxl-12 col-lg-12 col-md-12">
                              <div className="form-group">
                                <label className="form-label">
                                  {" "}
                                  Email : {statusData.email}
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-xxl-12 col-lg-12 col-md-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Mobile : {statusData.mobile}
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-xxl-12 col-lg-12 col-md-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Assets : {statusData.assets}
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-xxl-12 col-lg-12 col-md-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Country : {statusData.country}
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-xxl-12 col-lg-12 col-md-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Status : {statusData.status}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
