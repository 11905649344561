import './App.css';
import { Fragment } from 'react'; 
import RoutesList from './Routes'; 
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from "react-redux";
import store from './store';
import { PersistGate } from 'redux-persist/integration/react';
import persistStore from 'redux-persist/es/persistStore';

let persistor = persistStore(store)

function App() {
  return (
    
    <Fragment >
      <Provider store={store}>
      
      <RoutesList /> 
     
      </Provider> 
   
    </Fragment>
    

  );
}

export default App;
