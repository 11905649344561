const initialLogin={login:false,token:null,user:null}
export const LoginReducer = (state=initialLogin,action)=>{

    switch(action.type){
        case "LOGIN": 
        localStorage.setItem('login',action.payload.login);
        localStorage.setItem('token',action.payload.token);
        return {login:action.payload.login,token:action.payload.token,user:action.payload.user}
        default:
            return state;
    }
}