import React, { Fragment } from 'react'
import {    Route, Routes,useLocation } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion';
import { Dashboard } from './pages/dashboard'
import { CourseCategory } from './pages/course-category'
import { Login } from './pages/login';
import { TrustRequest } from './pages/trust-request'
import { RequestList } from './pages/request-list'
import { TrustDetails } from './pages/trust-details'
 



const RoutesList = () => {
   

    const location = useLocation();

    return (
        <Fragment>
               <AnimatePresence mode='wait'>
            <Routes location={location} key={location.pathname}>
             <Route path='/' element={<TrustRequest />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/trust-request' element={<RequestList />} />
            <Route path='/course-category' element={<CourseCategory />} />
            <Route path='/admin' element={<Login />} />
             <Route path='/trust-details' element={<TrustDetails />} />
            <Route path='/trust-details/:id' element={<TrustDetails />} />

            </Routes>
            </AnimatePresence>
        </Fragment>
    )
}

export default RoutesList