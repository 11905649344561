import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useNavigate } from "react-router-dom";
import userdemo from "../../assets/images/user.png";
import  { Button, Drawer,Tooltip,Menu ,Avatar,MenuItem} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { changeAuth } from '../../actions/loginAction';
import host from '../../host';
 
export default function HeaderPage() {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user_name = localStorage.getItem('user_name')
  const email = localStorage.getItem('email')
  const handleToggleClick = () => {
  setIsActive(!isActive);
  };
  useEffect(() => {
  if (isActive) {
  document.body.classList.add('mini-sidebar');
  } else {
  document.body.classList.remove('mini-sidebar');
  }
  }, [isActive]);


const [open, setOpen] = useState(false); 
const handleDrawer = () => {
setOpen(!open);
};
const [anchorEl, setAnchorEl] = React.useState(null);
const open2 = Boolean(anchorEl);
const handleClick = (event) => {
setAnchorEl(event.currentTarget);
};
const handleClose = () => {
setAnchorEl(null);
};
 //this below function for openstatus box    start// 
 const [cnndidateanchorEl3, candidatesetAnchorEl3] = React.useState(null);
 const addcandidateopen3 = Boolean(cnndidateanchorEl3);
 const candidatehandleClick3 = (event) => {
 candidatesetAnchorEl3(event.currentTarget);
 };
 const handleClosecandidate = () => {
 candidatesetAnchorEl3(null);
 };
 //this below function for openstatus box end// 

 const handleLogOut = () => {
   
   let mainquery = '/api/v1/admin/logout'

   fetch(host.hostname + mainquery,{
      method: "GET",
      headers: {
         "Content-type": "application/json",
         "Authorization": `Bearer ${localStorage.getItem('token')}`,
      }
    })
      .then((res) => {
          return res.json();
      })
      .then((data) => {
        if (data.success) {
         navigate('/admin');
         dispatch(changeAuth(false,null,null));
         localStorage.clear();
        }
      })
      .catch((err) => {
        console.log(err);
      });
   }
 

return (
<>
<header  className=" " id="page-topbar">
   <div className="navbar-header">
      <div className="d-flex align-items-center">
         {/* LOGO */}
       
         <Link to="#" className="  px-3 font-size-16 header-item icon-lg" onClick={handleToggleClick}>
         <span className="bars">
         <span className="top" />
         <span className="middle" />
         <span className="bottom" />
         </span>
         </Link>
         {/* App Search*/}
      </div>
      <div className="d-flex align-items-center">
      
     
         <div className="user-profile border-right head_right_icon  ">
            <Tooltip className="blue-tooltip"  title="My Account"  placement="bottom" arrow>
               <Link to="#" className=" "  onClick={handleDrawer}>
               <Avatar className='profile_avtar'    >{user_name ? user_name.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '') : ''}</Avatar>
               </Link>
            </Tooltip>
            <Drawer
            variant="temporary"
            anchor="right"
            open={open}
            onClose={handleDrawer}
            ModalProps={{
            keepMounted: true,  
            }}
            >
            <div className=" profile-slider" id="profile">
               <div className="profile-header">
                  <Link to="#" className="slide-close" onClick={handleDrawer}>
                  <span>X</span></Link>
                  <div className="profile-card">
                     <div className="image-icon">
                        <img src={userdemo} alt="" />
                        {/* <div className="profile-loader">
                           <CircularProgress />
                        </div> 
                        <div className="thumb-edit">
                           <input type="file" id="imageUpload"  className="ec-image-upload" accept=".png, .jpg, .jpeg" />
                           <label For="imageUpload"><i className="las la-pencil-alt svg_img header_svg" /></label>
                        </div>*/}
                     </div>
                     <div className="profile-title ">
                        <h5>{ user_name}</h5>
                        <Link to="" className="d-block">
                        {email}</Link>
                        {/* <span className="badge bg-inverse-white border d-inlin-block mt-2 ">Organisation Name</span> */}
                     </div>
                     <div className="profile-btn ">
                        
                        <button type="button" className="btn btn-danger" onClick={handleLogOut}> Log Out</button>
                        {/* <Link to="/reset-password">
                        <button type="button" className="btn btn-primary"> Reset Password</button></Link> */}
                     </div>
                  </div>
               </div>
               <div className="slide-body"> 
               </div>
            </div>
            </Drawer>
         </div>
       
      </div>
   </div>
</header>

 
</>
)
}