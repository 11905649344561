import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import logo from "../assets/images/logo-blue.png";
import { useDispatch, useSelector } from "react-redux";
import { changeAuth } from "../actions/loginAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoginSidebar from "../components/login-slider";
import googleicon from "../assets/images/google-icon.svg";
import LoadingButton from "@mui/lab/LoadingButton";
import { useGoogleLogin } from "@react-oauth/google";
import host from "../host";

// import ReCAPTCHA from "react-google-recaptcha";
export const Login = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
  };
  const recaptchaRef = React.createRef();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
  });

  const otpValidation = Yup.object({
    otp: Yup.string().required("OTP is required"),
  });

  const formikOtp = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: otpValidation,
  });

  const [message, setMessage] = useState("");
  const [successColor, setSuccessColor] = useState(false);
  const initialTime = 60; // Initial countdown time in seconds
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [otpSent, setOtpSent] = useState(false);
  const [otpCount, setOtpCount] = useState(4);

  const sendOTP = () => {
    setMessage("");
    setLoading(true);

    fetch(host.hostname + "/api/v1/admin/sendEmailOTP", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email: formik.values.email,
        password: formik.values.password,
      }),
    })
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          // dispatch(changeAuth(true,data.token,data.user))
          setOtpSent(true);
          setSuccessColor(true);
          setMessage(
            otpCount === 4
              ? "Otp sent successfully!"
              : "Otp resent successfully!"
          );
          setOtpCount(otpCount - 1);

          // Start the 60-second cooldown timer
          setTimeLeft(60);

          const timerInterval = setInterval(() => {
            setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
          }, 1000);

          setTimeout(() => {
            clearInterval(timerInterval);
            setTimeLeft(0);
          }, 60000);
        } else {
          setOtpSent(false);
          setSuccessColor(false);
          setMessage(data.message);
        }
      })
      .catch((_err) => {
        setLoading(false);
      });
  };

  const loginUser = () => {
    setMessage("");
    setLoading(true);

    fetch(host.hostname + "/api/v1/admin/login", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email: formik.values.email,
        password: formik.values.password,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          setLoading(false);
          localStorage.setItem("user_name", data.user.user_name);
          localStorage.setItem("email", data.user.email);
          dispatch(changeAuth(true, data.token, data.user));
          navigate("/trust-request");
        } else {
          setMessage(data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="auth-page">
        <div className="container-fluid p-0">
          <div className="row g-0">
            
            <div className="col-xxl-12 col-lg-12 col-md-12">
              <div className="auth-full-page-content d-flex p-sm-4 p-4">
                <div className="form-width login-width ">
                  <div className="d-flex flex-column h-100">
                    <div className="auth-content my-auto logincorporate">
                      <div className="mb-4 mb-md-4 text-center">
                        <div className="flip-logo">
                          <img className=" " src={logo} height={50} />
                        </div>
                      </div>
                      <div className="welcometext text-center">
                        <h5 className="mb-0">Welcome </h5>
                      </div>

                      <form className=" mt-2" action="index.html">
                        <div className="row">
                          <div className="col-xxl-12 col-lg-12 col-md-12">
                            <div className="form-group">
                              <label className="form-label"> Email</label>
                              <TextField
                                size="small"
                                type="text"
                                name="email"
                                placeholder="Enter business email"
                                disabled={otpSent}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.email &&
                                  Boolean(formik.errors.email)
                                }
                                helperText={
                                  formik.touched.email && formik.errors.email
                                }
                              />
                            </div>
                          </div>
                          <div className="col-xxl-12 col-lg-12 col-md-12">
                            <div className="form-group">
                              <div className="d-flex align-items-start">
                                <div className="flex-grow-1">
                                  <label className="form-label">Password</label>
                                </div>
                                {/* <div className="flex-shrink-0">
                                             <div className="forgotpass">
                                                <Link to="/forgot-password" className=" ">
                                                Forgot password?</Link>
                                             </div>
                                          </div> */}
                              </div>
                              <div className="input-group auth-pass-inputgroup">
                                <TextField
                                  size="small"
                                  type={showPassword ? "text" : "password"}
                                  name="password"
                                  placeholder="Enter password"
                                  disabled={otpSent}
                                  InputProps={{
                                    // <-- This is where the toggle button is added.
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                        >
                                          {showPassword ? (
                                            <Visibility />
                                          ) : (
                                            <VisibilityOff />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                  value={formik.values.password}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  error={
                                    formik.touched.password &&
                                    Boolean(formik.errors.password)
                                  }
                                  helperText={
                                    formik.touched.password &&
                                    formik.errors.password
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl-12 col-lg-12 col-md-12">
                            <div className="form-group">
                              {/* <ReCAPTCHA
                                          ref={recaptchaRef}
                                          sitekey="Your client site key"
                                          /> */}
                            </div>
                          </div>
                          <div className="col-xxl-12 col-lg-12 col-md-12">
                            <div className="form-group">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="remember-check"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="remember-check"
                                >
                                  Remember me
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>

                      <div className="col-xxl-12 col-lg-12 col-md-12">
                        <div className="form-group">
                          <LoadingButton
                            disabled={
                              otpSent ? !formikOtp.isValid : !formik.isValid
                            }
                            type="button"
                            variant="contained"
                            className="btn btn-primary w-100  "
                            onClick={(e) => {
                              e.preventDefault();
                              if (!otpSent) {
                                loginUser();
                              } else {
                                loginUser();
                              }
                            }}
                            controlled={true}
                            loading={loading}
                          >
                            {otpSent ? "Log In" : "Log In"}
                          </LoadingButton>
                        </div>
                      </div>
                      <div className="  text-center notmember">
                        <div
                          style={{
                            color: successColor ? "green" : "red",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: 10,
                          }}
                        >
                          {message}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
