import AddBreadcrumbs from "../components/add-breadcrumb";
import HeaderPage from "./elements/header";
import SidebarPage from "./elements/sidebar";
import { motion } from "framer-motion"
 

const MainPage = (props) => {
    return (
        <> 

<div className="layout-wrapper">


    


<SidebarPage/>
           
            <div className="main-content">
            <HeaderPage/>
            <div className="page-content"> 
      
            <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }} // Set the transition duration to 0.5 seconds
    > 
 {/* <AddBreadcrumbs/> */}
            {props.children} 
            </motion.div>
                </div>
                </div>

                </div>
           
        </>
    )
}
export default MainPage;
